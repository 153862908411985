import { DSSpacer } from '@/components/ds/Spacer/Spacer'
import { useEffect, useState } from 'react'
import { trackD30PDPViewEvent } from '@/lib/gtm/gtmV2'
import { D30PricingCard } from '../product-details-page/D30PricingCard'

export enum ProductsEnum {
  GB_DAILY30_4_MONTH = 'GB_DAILY30_4_MONTH',
  GB_DAILY30_1_MONTH = 'GB_DAILY30_1_MONTH',
}

export type D30ProductType = {
  id: ProductsEnum
  name: string
  price: number
  currency?: 'gbp' | 'usd'
  previousPrice?: number
  title: string
  ctaText?: string
  featureList?: string[]
  tagline?: string
}

const PRODUCT_GB_DAILY30_4_MONTH: D30ProductType = {
  id: ProductsEnum.GB_DAILY30_4_MONTH,
  name: 'Daily30 4 Month Subscription',
  price: 13300,
  previousPrice: 15600,
  title: '4-month subscription',
  ctaText: 'Subscribe now',
  featureList: [
    '120 daily servings',
    'Delivery every 4 months',
    'Free ZOE tin and magnetic scoop',
    '£1.10 per serving',
    'Change or cancel anytime',
  ],
  tagline: 'ZOE members get an extra 15% off',
}

const PRODUCT_GB_DAILY30_1_MONTH: D30ProductType = {
  id: ProductsEnum.GB_DAILY30_1_MONTH,
  name: 'Daily30 1 Month Subscription',
  price: 3900,
  title: '1-month subscription',
  ctaText: 'Subscribe now',
  featureList: [
    '30 daily servings',
    'Delivery every 30 days',
    'Free ZOE tin and magnetic scoop',
    '£1.30 per serving',
    'Change or cancel anytime',
  ],
  tagline: 'ZOE members get an extra 15% off',
}

interface D30PriceSelectorProps {
  disabled?: boolean
  config?: any
}

export const D30PriceSelectorBlock = ({ disabled, config }: D30PriceSelectorProps) => {
  const [selectedCard, setSelectedCard] = useState<D30ProductType>(PRODUCT_GB_DAILY30_4_MONTH)

  useEffect(() => {
    if (!disabled) {
      setSelectedCard(PRODUCT_GB_DAILY30_4_MONTH)
      window.sessionStorage.setItem('d30SelectedProduct', PRODUCT_GB_DAILY30_4_MONTH.id)
    }
  }, [])

  useEffect(() => {
    trackD30PDPViewEvent({ productName: selectedCard.name, productPrice: selectedCard.price, itemId: selectedCard.id })
  }, [selectedCard])

  const handleClick = (product: D30ProductType) => {
    setSelectedCard(product)
    window.sessionStorage.setItem('d30SelectedProduct', product.id)
  }

  return (
    <>
      <D30PricingCard
        id={PRODUCT_GB_DAILY30_4_MONTH.id}
        title={config?.PRODUCT_GB_DAILY30_4_MONTH?.title || '4-month subscription'}
        price={config?.PRODUCT_GB_DAILY30_4_MONTH?.price || 13300}
        label={config?.PRODUCT_GB_DAILY30_4_MONTH?.label}
        previousPrice={config?.PRODUCT_GB_DAILY30_4_MONTH?.previousPrice || 15600}
        currency={config?.PRODUCT_GB_DAILY30_4_MONTH?.currency || 'gbp'}
        isSelected={selectedCard === PRODUCT_GB_DAILY30_4_MONTH}
        onClick={() => handleClick(PRODUCT_GB_DAILY30_4_MONTH)}
        ctaText={config?.PRODUCT_GB_DAILY30_4_MONTH?.ctaText || 'Subscribe now'}
        ctaLink="/daily30/checkout?product=GB_DAILY30_4_MONTH"
        featureList={
          config?.PRODUCT_GB_DAILY30_4_MONTH?.featureList || [
            '120 daily servings',
            'Delivery every 4 months',
            'Free ZOE tin and magnetic scoop',
            '£1.10 per serving',
            'Change or cancel anytime',
          ]
        }
        tagline={config?.PRODUCT_GB_DAILY30_4_MONTH?.tagline || 'ZOE members get an extra 15% off'}
        disabled={disabled}
        analyticsLinkText="4 month-Subscribe now"
      />
      <DSSpacer size={24} direction="vertical" />
      <D30PricingCard
        id={PRODUCT_GB_DAILY30_1_MONTH.id}
        title={config?.PRODUCT_GB_DAILY30_1_MONTH?.title || 'Monthly subscription'}
        price={config?.PRODUCT_GB_DAILY30_1_MONTH?.price || 3900}
        currency={config?.PRODUCT_GB_DAILY30_1_MONTH?.currency || 'gbp'}
        isSelected={selectedCard === PRODUCT_GB_DAILY30_1_MONTH}
        onClick={() => handleClick(PRODUCT_GB_DAILY30_1_MONTH)}
        ctaText={config?.PRODUCT_GB_DAILY30_1_MONTH?.ctaText || 'Subscribe now'}
        ctaLink="/daily30/checkout?product=GB_DAILY30_1_MONTH"
        featureList={
          config?.PRODUCT_GB_DAILY30_1_MONTH?.featureList || [
            '30 daily servings',
            'Delivery every 30 days',
            'Free ZOE tin and magnetic scoop',
            '£1.30 per serving',
            'Change or cancel anytime',
          ]
        }
        tagline={config?.PRODUCT_GB_DAILY30_1_MONTH?.tagline || 'ZOE members get an extra 15% off'}
        disabled={disabled}
        analyticsLinkText="1 month-Subscribe now"
      />
    </>
  )
}
