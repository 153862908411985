import { DSGrid } from '@/components/ds/Grid/Grid'
import React, { useMemo } from 'react'
import { GridRecord } from 'types'
import { GridBlock } from './GridBlock'
import { SpacerBlock } from './Spacer'
import { FragmentComponent } from '@/types/graphql'
import { getBreakpointForWidth } from '@/components/ds/breakpoints'
import { useWindowSize } from '@/lib/hooks/useWindowSize'
import { useExperimentContext } from '@/context/ExperimentContext'

const fragment = `
  fragment GridFragment on GridRecord {
    __typename
    id
    visibility
    content {
      ...GridBlockFragment
      ...SpacerBlockFragment
    }
    backgroundColor
    mobileBackgroundColor
    rounded
    maxWidth
    backgroundFullWidth
    dataTestId
  }
`

const renderBlock = (record) => {
  switch (record.__typename) {
    case 'GridBlockRecord':
      return <GridBlock key={record.id} record={record} />
    case 'SpacerRecord':
      return <SpacerBlock key={record.id} record={record} />
  }
}

export const Grid: FragmentComponent<{}, GridRecord> = ({ record }) => {
  const { shouldRenderContent } = useExperimentContext()
  const window = useWindowSize()

  const isVisible = useMemo(() => {
    const visibility = record?.visibility ?? []
    const currentBreakpoint = getBreakpointForWidth(window.width ?? 0)

    return (
      (visibility.includes('small') && currentBreakpoint === 's') ||
      (visibility.includes('medium') && currentBreakpoint === 'm') ||
      (visibility.includes('large') && currentBreakpoint === 'l') ||
      (visibility.includes('extra_large') && currentBreakpoint === 'xl')
    )
  }, [record.visibility, window.width])

  if (!shouldRenderContent(record.dataTestId)) {
    return null
  }

  return isVisible ? (
    <DSGrid
      backgroundColor={record?.backgroundColor}
      mobileBackgroundColor={record?.mobileBackgroundColor}
      rounded={record?.rounded}
      maxWidth={record?.maxWidth}
      backgroundFullWidth={record?.backgroundFullWidth}
      dataTestId={record?.dataTestId}
    >
      {record?.content?.map((block, i) => renderBlock(block))}
    </DSGrid>
  ) : null
}

Grid.fragment = fragment
Grid.recordName = 'GridRecord'
