import { BREAKPOINTS } from '@/components/ds/breakpoints'
import React from 'react'
import styled from 'styled-components'
import { ResponsiveGridBlockRecord } from 'types'
import { ResponsiveGridAreaBlock } from './ResponsiveGridAreaRecord'
import { FragmentComponent } from '@/types/graphql'

const fragment = `
  fragment ResponsiveGridBlockFragment on ResponsiveGridBlockRecord {
    __typename
    id
    gridAreas {
      ...ResponsiveGridAreaFragment
    }
    gridGap
    gridRowGap
    gridColumns
    templateRows
    mobileGridColumns
    gridTemplateAreas
    mobileGridTemplateAreas
    alignItems
  }

  ${ResponsiveGridAreaBlock.fragment}
`

const StyledResponsiveGridContainer = styled.div<{
  $gridColumns: number
  $gridGap: number
  $gridRowGap?: number
  $mobileGridColumns: number
  $gridTemplateAreas: string
  $gridTemplateRows?: string
  $mobileGridTemplateAreas: string
  $alignItems: string
}>`
  display: grid;
  ${({ $gridGap }) => `column-gap: var(--grid-${$gridGap})`};
  ${({ $gridRowGap }) => `row-gap: var(--grid-${$gridRowGap})`};
  grid-template-columns: ${({ $gridColumns }) => `repeat(${$gridColumns}, 1fr)`};

  grid-template-areas: ${({ $gridTemplateAreas }) => `${$gridTemplateAreas}`};
  align-items: ${({ $alignItems }) => `${$alignItems}` || 'center'};

  @media (max-width: ${BREAKPOINTS.l}) {
    grid-template-areas: ${({ $mobileGridTemplateAreas }) => `${$mobileGridTemplateAreas}`};
    grid-template-columns: ${({ $mobileGridColumns }) => `repeat(${$mobileGridColumns}, 1fr)`};
  }

  @media (min-width: ${BREAKPOINTS.l}) {
    ${({ $gridTemplateRows }) => $gridTemplateRows && `grid-template-rows: ${$gridTemplateRows};`}
  }
`

export const ResponsiveGridBlock: FragmentComponent<{}, ResponsiveGridBlockRecord> = ({ record }) => {
  return (
    <StyledResponsiveGridContainer
      $gridGap={record?.gridGap}
      $gridRowGap={record?.gridRowGap}
      $gridColumns={record?.gridColumns}
      $mobileGridColumns={record?.mobileGridColumns}
      $gridTemplateAreas={record?.gridTemplateAreas}
      $gridTemplateRows={record?.templateRows}
      $mobileGridTemplateAreas={record?.mobileGridTemplateAreas}
      $alignItems={record?.alignItems}
    >
      {record?.gridAreas.map((area) => <ResponsiveGridAreaBlock key={area?.id} record={area} />)}
    </StyledResponsiveGridContainer>
  )
}

ResponsiveGridBlock.fragment = fragment
ResponsiveGridBlock.recordName = 'ResponsiveGridBlockRecord'
