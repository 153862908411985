import Image from 'next/image'

type LogoProps = {
  size?: 'normal' | 'medium' | 'large'
  colour?: 'dark' | 'white'
  margin?: string
}

const LOGO_SIZES = {
  normal: {
    height: 16,
    width: 66,
  },
  medium: {
    height: 20,
    width: 80,
  },
  large: {
    height: 24,
    width: 125,
  },
}

export const TrustpilotLogo = ({ size = 'normal', colour = 'dark', margin = 'auto' }: LogoProps) => {
  const dimensions = LOGO_SIZES[size]
  return (
    <Image
      alt="by Trustpilot."
      src={
        colour === 'dark'
          ? '/assets/img/trustpilot/trustpilot-logo-dark.svg'
          : `/assets/img/trustpilot/trustpilot-logo-${colour}.svg`
      }
      width={dimensions.width}
      height={dimensions.height}
      style={{ margin }}
    />
  )
}
