import { DSFlex } from '@/components/ds/Flex/Flex'
import { Form, Formik } from 'formik'

// Think about migrating away from Formik to use https://react-spectrum.adobe.com/react-aria

interface DSFormProps {
  initialValues: Record<string, any>
  onSubmit: (values: Record<string, any>) => void
  validateOnChange: boolean
  children: React.ReactNode
  validationSchema?: any
}

export const DSForm = ({ initialValues, onSubmit, validateOnChange, children, validationSchema }: DSFormProps) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={validateOnChange}
    >
      {() => (
        <DSFlex direction="column">
          <Form>{children}</Form>
        </DSFlex>
      )}
    </Formik>
  )
}
