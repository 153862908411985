import { FragmentComponent } from '@/types/graphql'
import React from 'react'
import Carousel from '@/components/pages/homepage2024/content/carousel/Carousel'
import { CarouselItem } from '@/components/pages/homepage2024/content/carousel/CarouselItem'
import { ImageCarouselRecord } from '../../types'
import { SimpleImageBlock } from '@/blocks/DesignSystem/SimpleImage'
import { DSStyledStructuredText } from '@/blocks/DesignSystem/DSStyledStructuredText'
import { useWindowSize } from '@/lib/hooks/useWindowSize'

const fragment = `
  fragment ImageCarouselBlockFragment on ImageCarouselRecord {
    __typename
    id
    items {
      title
      description {
        value
      }
      image {
        ...SimpleImageBlockFragment
      }
    }
    theme
  }
`

export const ImageCarouselBlock: FragmentComponent<{}, ImageCarouselRecord> = ({ record }) => {
  const { breakpoint } = useWindowSize()

  const content =
    record?.items?.map(({ title, description, image }, index) => {
      return (
        <CarouselItem
          key={`carousel-item-${index}`}
          image={<SimpleImageBlock record={image} />}
          title={title}
          description={description && <DSStyledStructuredText data={description.value} />}
          containerWidth={breakpoint === 's' ? 300 : 411}
        />
      )
    }) || []

  return <Carousel content={content} theme={record.theme} />
}

ImageCarouselBlock.fragment = fragment
ImageCarouselBlock.recordName = 'ImageCarouselRecord'
