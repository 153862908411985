import styled, { css } from 'styled-components'
import { BREAKPOINTS } from '../breakpoints'
import { DSGridSpacing } from 'styles/ds/gridSpacingStyles'
import { DSFlex } from '../Flex/Flex'

type ColSpanNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
export type GridSurfaceColour =
  | 'neutral'
  | 'cream-subtle'
  | 'teal-subtle'
  | 'aubergine-bold'
  | 'teal-bold'
  | 'navy-subtle'

interface GridProps {
  maxWidth?: number
  margin?: DSGridSpacing
  backgroundColor?: GridSurfaceColour
  mobileBackgroundColor?: GridSurfaceColour
  rounded?: boolean
  children: React.ReactNode
  textAlign?: 'center' | 'left' | 'right'
  padded?: boolean
  backgroundFullWidth?: boolean
  dataTestId?: string
}

interface BreakpointProps {
  colSpan?: ColSpanNumber
  offset?: ColSpanNumber
  order?: ColSpanNumber
  gutter?: DSGridSpacing
}

export interface BreakConfigProps {
  s?: BreakpointProps
  m?: BreakpointProps
  l?: BreakpointProps
  xl?: BreakpointProps
}

interface GridBlockProps {
  breakConfig?: BreakConfigProps
  children: React.ReactNode
  backgroundColor?: GridSurfaceColour
  mobileBackgroundColor?: GridSurfaceColour
  rounded?: boolean
  textAlign?: 'center' | 'left' | 'right'
  padded?: boolean
  dataTestId?: string
}

interface GridConfigBreakpointProps {
  maxColumns: ColSpanNumber
  gutter: DSGridSpacing
  margin: DSGridSpacing
}

export interface GridConfigProps {
  s: GridConfigBreakpointProps
  m: GridConfigBreakpointProps
  l: GridConfigBreakpointProps
  xl: GridConfigBreakpointProps
  maxWidth: number
}

const CONTAINER_MAX_WIDTH = 1360

const GRID_DEFAULT_CONFIG: GridConfigProps = {
  maxWidth: CONTAINER_MAX_WIDTH,
  s: {
    maxColumns: 2,
    margin: 16,
    gutter: 16,
  },
  m: {
    maxColumns: 6,
    margin: 32,
    gutter: 24,
  },
  l: {
    maxColumns: 12,
    margin: 72,
    gutter: 32,
  },
  xl: {
    maxColumns: 12,
    margin: 72,
    gutter: 32,
  },
}

const StyledGridContainer = styled.div<{
  $backgroundColor: GridSurfaceColour
  $mobileBackgroundColor: GridSurfaceColour
}>`
  ${({ $backgroundColor }) => `background-color: var(--colour-surface-${$backgroundColor})`};
  ${({ $mobileBackgroundColor }) =>
    $mobileBackgroundColor &&
    `@media (max-width: ${BREAKPOINTS.m}) { background-color: var(--colour-surface-${$mobileBackgroundColor}) }`};
`

const StyledGrid = styled.div<{
  $maxWidth: number
  $backgroundColor: GridSurfaceColour
  $mobileBackgroundColor: GridSurfaceColour
  $rounded: boolean
}>`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin: 0 var(--grid-${GRID_DEFAULT_CONFIG.s.margin});

  @media (min-width: ${BREAKPOINTS.m}) {
    margin: 0 var(--grid-${GRID_DEFAULT_CONFIG.m.margin});
  }

  @media (min-width: ${BREAKPOINTS.l}) {
    margin: 0 var(--grid-${GRID_DEFAULT_CONFIG.l.margin});
  }

  ${({ $backgroundColor }) => `background-color: var(--colour-surface-${$backgroundColor})`};
  ${({ $maxWidth }) => `max-width: ${$maxWidth}px`};
  ${({ $rounded }) => $rounded && `border-radius: var(--radius-24)`};
  ${({ $mobileBackgroundColor }) =>
    $mobileBackgroundColor &&
    `@media (max-width: ${BREAKPOINTS.m}) { background-color: var(--colour-surface-${$mobileBackgroundColor}) }`};
  width: 100%;
`

const StyledGridBlock = styled.div<{
  $breakConfig?: BreakConfigProps
  $smallerBreakpoint?: 's' | 'm' | 'l' | 'xl'
  $backgroundColor: GridSurfaceColour | undefined
  $mobileBackgroundColor: GridSurfaceColour | undefined
  $rounded: boolean
  $textAlign: 'center' | 'left' | 'right'
  $padded?: boolean
}>`
  ${({ $breakConfig, $smallerBreakpoint, $padded }) =>
    $breakConfig &&
    $smallerBreakpoint &&
    $breakConfig[$smallerBreakpoint] &&
    css`
      width: ${$breakConfig[$smallerBreakpoint]?.colSpan
        ? (Number($breakConfig[$smallerBreakpoint]?.colSpan) / GRID_DEFAULT_CONFIG[$smallerBreakpoint]?.maxColumns) *
            100 +
          '%'
        : '100%'};
      margin-left: ${$breakConfig[$smallerBreakpoint]?.offset
        ? (Number($breakConfig[$smallerBreakpoint]?.offset) / GRID_DEFAULT_CONFIG[$smallerBreakpoint]?.maxColumns) *
            100 +
          '%'
        : '0'};
      order: ${$breakConfig[$smallerBreakpoint]?.order ? $breakConfig[$smallerBreakpoint]?.order : 'initial'};
      padding: ${$padded ? `0 var(--grid-${GRID_DEFAULT_CONFIG[$smallerBreakpoint]?.gutter})` : '0'};
    `}

  ${({ $breakConfig, $padded }) =>
    $breakConfig &&
    Object.entries($breakConfig).map(
      ([breakpoint]) => css`
        @media (min-width: ${BREAKPOINTS[breakpoint]}) {
          width: ${$breakConfig[breakpoint].colSpan
            ? ($breakConfig[breakpoint].colSpan / GRID_DEFAULT_CONFIG[breakpoint].maxColumns) * 100 + '%'
            : '100%'};
          margin-left: ${$breakConfig[breakpoint].offset
            ? ($breakConfig[breakpoint].offset / GRID_DEFAULT_CONFIG[breakpoint].maxColumns) * 100 + '%'
            : '0'};
          order: ${$breakConfig[breakpoint].order ? $breakConfig[breakpoint].order : 'initial'};
          padding: ${$padded ? `0 var(--grid-${GRID_DEFAULT_CONFIG[breakpoint]?.gutter})` : '0'};
        }
      `,
    )}
  ${({ $backgroundColor }) => `background-color: var(--colour-surface-${$backgroundColor})`};
  ${({ $mobileBackgroundColor }) =>
    $mobileBackgroundColor &&
    `@media (max-width: ${BREAKPOINTS.m}) { background-color: var(--colour-surface-${$mobileBackgroundColor}) }`};
  ${({ $rounded }) => $rounded && `border-radius: var(--radius-24)`};
  ${({ $textAlign }) => `text-align: ${$textAlign};`};
`

export const DSGrid = ({
  children,
  maxWidth,
  backgroundColor = 'neutral',
  mobileBackgroundColor = 'neutral',
  rounded = false,
  padded = true,
  backgroundFullWidth = false,
  dataTestId,
}: GridProps) => {
  const gridContent = (
    <DSFlex direction="row" justifyContent="center" alignItems="center">
      <StyledGrid
        $maxWidth={maxWidth ?? GRID_DEFAULT_CONFIG.maxWidth}
        $backgroundColor={backgroundColor}
        $mobileBackgroundColor={mobileBackgroundColor}
        $rounded={rounded}
      >
        {children}
      </StyledGrid>
    </DSFlex>
  )

  return backgroundFullWidth ? (
    <StyledGridContainer
      $backgroundColor={backgroundColor}
      $mobileBackgroundColor={mobileBackgroundColor}
      {...(dataTestId && { 'data-test-id': dataTestId })}
    >
      {gridContent}
    </StyledGridContainer>
  ) : (
    <div {...(dataTestId && { 'data-test-id': dataTestId })}>{gridContent}</div>
  )
}

export const DSGridBlock = ({
  children,
  breakConfig,
  backgroundColor,
  mobileBackgroundColor,
  rounded = false,
  textAlign = 'left',
  padded = false,
}: GridBlockProps) => {
  if (breakConfig?.s?.colSpan && breakConfig?.s?.offset) {
    if (breakConfig.s?.colSpan + breakConfig.s?.offset > GRID_DEFAULT_CONFIG.s.maxColumns) {
      console.warn('Bad grid')
    }
  }

  const defaultBreakConfig: BreakConfigProps = {
    s: { colSpan: 2 },
    m: { colSpan: 6 },
    l: { colSpan: 12 },
    xl: { colSpan: 12 },
  }
  const smallerBreakpoint = breakConfig?.s
    ? 's'
    : breakConfig?.m
      ? 'm'
      : breakConfig?.l
        ? 'l'
        : breakConfig?.xl
          ? 'xl'
          : 's'
  return (
    <StyledGridBlock
      $breakConfig={{ ...defaultBreakConfig, ...breakConfig }}
      $smallerBreakpoint={smallerBreakpoint}
      $backgroundColor={backgroundColor}
      $mobileBackgroundColor={mobileBackgroundColor}
      $rounded={rounded}
      $textAlign={textAlign}
      $padded={padded}
    >
      {children}
    </StyledGridBlock>
  )
}
