import { Field } from 'formik'
import styled from 'styled-components'

const StyledCheckboxLabel = styled.label`
  display: block;
  position: relative;
  margin-top: 15px;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
  font-size: ${(props) => props.theme.v2.typography.label.l.fontSize};
  line-height: ${(props) => props.theme.v2.typography.label.l.lineHeight};
  font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
`

const StyledCheckbox = styled(Field)`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ span:after {
    display: block;
  }
`

const StyledCheckmark = styled.span`
  position: absolute;
  top: -4px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: ${(props) => props.theme.v2.surface.cream100};
  border: 1px solid ${(props) => props.theme.v2.onSurface.subdued.border};
  border-radius: 4px;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }

  &:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid ${(props) => props.theme.v2.surface.yellow};
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`

export const Checkbox: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = ({ children, ...props }) => {
  return (
    <StyledCheckboxLabel>
      {children}
      <StyledCheckbox type="checkbox" {...props} />
      <StyledCheckmark />
    </StyledCheckboxLabel>
  )
}
