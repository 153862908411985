import { DSLinkText } from '@/components/ds/LinkText/LinkText'
import { DSText, DSTextEm, DSTextStrong } from '@/components/ds/Text/Text'
import { FragmentComponent } from '@/types/graphql'
import React, { ReactNode } from 'react'
import { TypographyRecord } from 'types'

const fragment = `
  fragment TypographyBlockFragment on TypographyRecord {
    __typename
    id
    content
    colour
    htmlTag
    elementId
    variant
    mobileVariant
    weight
    alignment
    mobileAlignment
    className
  }
`

/**
 * Returns the input string with the following sub-strings replaced by their respective components:
 * 1. "*text*" -> DSTextEm
 * 2. "**text**" -> DSTextStrong
 * 3. "[text](url)" -> DSLinkText
 */
const processMarkdown = ({ content, ...props }: TypographyRecord): ReactNode[] => {
  const markdownRegex = /(\*\*|__)(.*?)\1|(\*|_)(.*?)\3|\[(.*?)\]\((.*?)\)/g
  const parts: ReactNode[] = []

  let lastIndex = 0

  content.replace(markdownRegex, (match, _p1, boldText, _p3, italicText, linkText, linkUrl, offset) => {
    // Add the plain text before the match
    if (offset > lastIndex) {
      parts.push(content.slice(lastIndex, offset))
    }

    const commonProps = {
      variant: props.variant,
      colour: props.colour,
      weight: props.weight,
      align: props.alignment,
      mobileAlign: props.mobileAlignment,
      mobileVariant: props.mobileVariant || props.variant,
      id: props.elementId,
      className: props.className,
    }

    if (boldText) {
      // Bold (**text**)
      parts.push(
        <DSTextStrong key={parts.length} as="span" {...commonProps}>
          {boldText}
        </DSTextStrong>,
      )
    } else if (italicText) {
      // Italic (*text*)
      parts.push(
        <DSTextEm key={parts.length} as="span" {...commonProps}>
          {italicText}
        </DSTextEm>,
      )
    } else if (linkText && linkUrl) {
      // Link ([text](url))
      parts.push(
        <DSLinkText href={linkUrl} key={parts.length} target="_blank" className={props.className}>
          {linkText}
        </DSLinkText>,
      )
    }

    lastIndex = offset + match.length
    return match
  })

  if (lastIndex < content.length) {
    parts.push(content.slice(lastIndex))
  }

  return parts
}

export const TypographyBlock: FragmentComponent<{}, TypographyRecord> = ({ record }) => {
  return (
    <DSText
      variant={record?.variant}
      colour={record?.colour}
      as={record?.htmlTag}
      weight={record?.weight}
      align={record?.alignment}
      mobileAlign={record?.mobileAlignment}
      mobileVariant={record?.mobileVariant || record?.variant}
      id={record?.elementId}
      className={record?.className}
    >
      {processMarkdown(record)}
    </DSText>
  )
}

TypographyBlock.fragment = fragment
TypographyBlock.recordName = 'TypographyRecord'
