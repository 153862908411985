import { CHECKOUT_GB } from '@/lib/checkout/types'
import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'
import { GeoContext } from '../../context/geoContext'

export const DAILY_30_CHECKOUT_COUNTRY_ALLOW_LIST: string[] = [CHECKOUT_GB]

export type UseD30CheckoutCountryType = {
  isReady: boolean
  isValidD30Country: boolean
  country: string
}

export const useD30CheckoutCountry = (): UseD30CheckoutCountryType => {
  const router = useRouter()
  const { country: geoCountry } = useContext(GeoContext)

  const [isValidD30Country, setIsValidD30Country] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const [country, setCountry] = useState<string>('')

  useEffect(() => {
    if (!router.isReady) {
      return
    }

    const queryCountry = (router.query.country as string | undefined)?.toUpperCase()

    const resolvedCountry = geoCountry ?? queryCountry

    if (resolvedCountry.length) {
      setIsValidD30Country(DAILY_30_CHECKOUT_COUNTRY_ALLOW_LIST.includes(resolvedCountry))
      setCountry(resolvedCountry)
      setIsReady(true)
    }
  }, [geoCountry, router.query, router.isReady])

  return {
    isReady,
    isValidD30Country,
    country,
  }
}
