import { D30_NON_GB_WAITLIST_EMAIL_SUBMITTED } from '@/lib/braze/brazeConstants'
import { D30Waitlist } from './D30Waitlist'

interface D30NonGBWaitlistProps {
  country: string
}

export const D30NonGBWaitlist = ({ country }: D30NonGBWaitlistProps) => {
  if (country === 'US') {
    // todo - move this to dato
    return (
      <D30Waitlist
        title="Coming soon"
        subtitle="Be the first to join the revolution"
        brazeEventName={D30_NON_GB_WAITLIST_EMAIL_SUBMITTED}
        country={country}
        showMarketingConsent={false}
      />
    )
  }

  return (
    <D30Waitlist
      title="Daily30+ is not available in your country yet"
      subtitle="Be the first to hear when it’s available."
      brazeEventName={D30_NON_GB_WAITLIST_EMAIL_SUBMITTED}
      country={country}
    />
  )
}
