import { DSButton } from '@/components/ds/Button/Button'
import { DSModal } from '@/components/ds/Modal/Modal'
import { DSSpacer } from '@/components/ds/Spacer/Spacer'
import { DSText } from '@/components/ds/Text/Text'
import { useState } from 'react'
import { NutritionalContentModal } from './NutritionalContentModal'

export const IngredientFAQBody = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <DSText variant="fluid-paragraph-200" weight="lotaLight">
        Check out the{' '}
        <DSButton
          colourScheme="default"
          onPress={() => setIsModalOpen(true)}
          size="large"
          variant="tertiary"
          width="auto"
        >
          full nutritional information
        </DSButton>
        .
      </DSText>
      <DSSpacer size={24} direction="vertical" />
      <DSText variant="fluid-paragraph-200" weight="lotaLight">
        Ingredients:
      </DSText>
      <DSText variant="fluid-paragraph-200" weight="lotaLight">
        Check out the full nutritional information.Ingredients:Seeds: (flaxseed, chia seed, sunflower seed, pumpkin
        seed, hemp seed), red lentil flakes, grape seed, nuts: (almond, hazelnut, walnut), inulin fibre, quinoa puffed,
        spice mix: ( white mushroom, thyme, onion, parsley, turmeric, cumin, rosemary, garlic), mushroom mix: ( Lions
        mane, Reishi, Chaga, Shiitake, Cordyceps, Maitake, Tremella), red beet flakes, carrot flakes, nutritional yeast
        flakes, baobab, buckthorn.
      </DSText>

      <DSModal
        isOpen={isModalOpen}
        variant="daily30"
        onBackdropClick={() => setIsModalOpen(false)}
        onCloseButtonClick={() => setIsModalOpen(false)}
      >
        <NutritionalContentModal />
      </DSModal>
    </>
  )
}
