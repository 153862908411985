import { DSButton } from '@/components/ds/Button/Button'
import { DSFormErrorText } from '@/components/ds/FormErrorText/FormErrorText'
import { DSInput } from '@/components/ds/Input/Input'
import { DSSpacer } from '@/components/ds/Spacer/Spacer'
import { DSText } from '@/components/ds/Text/Text'
import { Checkbox } from '@/components/shared/form/Checkbox'
import { useFormikContext } from 'formik'

interface D30WaitlistFormValues {
  firstName: string
  email: string
}

interface D30WaitlistFormContentProps {
  showMarketingConsent: boolean
  ctaText: string
}

export const D30WaitlistFormContent = ({ showMarketingConsent, ctaText }: D30WaitlistFormContentProps) => {
  const { errors } = useFormikContext<D30WaitlistFormValues>()

  return (
    <>
      <DSInput id="firstName" name="firstName" type="text" placeholder="Your name" />
      {errors.firstName ? (
        <>
          <DSFormErrorText>{errors.firstName}</DSFormErrorText>
        </>
      ) : null}
      <DSSpacer direction="vertical" size={12} />
      <DSInput id="email" name="email" type="email" placeholder="Email" />
      {errors.email ? (
        <>
          <DSFormErrorText>{errors.email}</DSFormErrorText>
        </>
      ) : null}
      <DSSpacer direction="vertical" size={12} />

      <DSButton type="submit" variant="primary" colourScheme="default" size="large" width="full">
        <DSText as="span" variant="fluid-paragraph-200" weight="lotaSemiBold" colour="primary">
          {ctaText}
        </DSText>
      </DSButton>

      {showMarketingConsent && (
        <>
          <DSSpacer direction="vertical" size={12} />
          <Checkbox name="marketingConsent" id="marketingConsent">
            <DSText variant="fluid-label-100" weight="lotaLight">
              Opt in to receive ongoing science and nutrition emails, discounts, and offers from ZOE.
            </DSText>
          </Checkbox>
        </>
      )}
    </>
  )
}
