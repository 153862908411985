import styled from 'styled-components'

export const StyledLoadingSpinner = styled.div`
  text-align: center;
  margin-top: 60px;

  span {
    width: 48px;
    height: 48px;
    border: 5px solid ${(props) => props.theme.v2.onSurface.text};
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin: 0 auto;

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`

export const LoadingSpinner = () => (
  <StyledLoadingSpinner>
    <span></span>
  </StyledLoadingSpinner>
)
