import { DSSlider } from '@/components/ds/Slider/Slider'
import { useEffect, useState } from 'react'

const fragment = `
  fragment D30GalleryBlockFragment on D30GalleryRecord {
    __typename
    id
    content
  }
`

export const D30GalleryBlock = ({ record }) => {
  const [viewportWidth, setViewportWidth] = useState(0)

  useEffect(() => {
    setViewportWidth(window?.innerWidth)
    const handleResize = () => setViewportWidth(window?.innerWidth)
    window?.addEventListener('resize', handleResize)

    return () => window?.removeEventListener('resize', handleResize)
  }, [])

  const getSlideWidth = () => {
    const slideGap = 112 // 16px gap + (2 * 48px padding)
    const minSlideWidth = 200
    const maxSlideWidth = 300

    const fittedSlideWidth = (viewportWidth - slideGap * 2) / 3

    if (fittedSlideWidth < minSlideWidth) {
      return maxSlideWidth
    }

    return Math.min(fittedSlideWidth, maxSlideWidth)
  }

  return (
    <DSSlider slideWidth={getSlideWidth()}>
      {['/assets/img/daily30/dinner.jpg', '/assets/img/daily30/lunch.jpg', '/assets/img/daily30/breakfast.jpg'].map(
        (imageUrl, index) => (
          <img src={imageUrl} key={index} width={'100%'} />
        ),
      )}
    </DSSlider>
  )
}

D30GalleryBlock.fragment = fragment
D30GalleryBlock.recordName = 'D30GalleryBlockRecord'
