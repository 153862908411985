type ResponsiveImageProps = {
  width?: number
  height?: number
  quality?: number
  fit?: string
}

export const responsiveImageBlock = ({
  width,
  height,
  // default quality is 75,
  // see https://github.com/imgix/imgix-url-params/blob/bd6bfc995904041ec36bde20bddb03fd43e28b60/dist/parameters.js#L2502
  quality = 75,
  fit = 'crop',
}: ResponsiveImageProps = {}) => {
  let imgixParams: string = `crop: focalpoint, fit: ${fit}, auto: format, q: ${quality}`

  if (width !== undefined) {
    imgixParams += `, w: ${width}`
  }

  if (height !== undefined) {
    imgixParams += `, h: ${height}`
  }

  return `
    responsiveImage(imgixParams: {${imgixParams}}) {
      alt
      aspectRatio
      base64
      bgColor
      height
      sizes
      src
      srcSet
      title
      webpSrcSet
      width
    }
  `
}
