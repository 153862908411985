import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { DSSpacer } from '../Spacer/Spacer'

const StyledContainer = styled.div<{ $isOverflowing: boolean }>`
  display: flex;
  gap: var(--grid-16);
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  justify-content: ${({ $isOverflowing }) => ($isOverflowing ? 'flex-start' : 'center')};
  // Hide scrollbar
  scrollbar-width: none; /* Firefox 64 */
  -ms-overflow-style: none; /* Internet Explorer 11 */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`

const StyledSlide = styled.div<{ $width: number }>`
  flex: 0 0 auto;
  scroll-snap-align: start;
  width: ${({ $width }) => `${$width}px`};
`

const StyledActiveIndex = styled.span<{ $variant: string }>`
  display: inline-block;
  width: 72px;
  height: 8px;
  background: ${({ $variant }) =>
    $variant === 'primary' ? 'var(--colour-surface-yellow-bold);' : 'var(--colour-surface-navy-bold);'};
  border-radius: var(--radius-full);
`

const StyledDot = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  background: var(--colour-surface-navy-bold);
  opacity: 0.16;
  border-radius: 50%;
  cursor: pointer;
`
const StyledSliderNavigator = styled.div`
  display: flex;
  justify-content: center;
  gap: var(--grid-12);
`

export const DSSlider = ({ slideWidth = 200, variant = 'primary', children }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const containerRef = useRef(null)

  const handleScroll = () => {
    if (containerRef.current) {
      const container = containerRef.current as HTMLDivElement
      const scrollLeft = container.scrollLeft
      const totalWidth = container.scrollWidth
      const containerWidth = container.offsetWidth
      const totalSlides = React.Children.count(children)
      let currentIndex = Math.round(scrollLeft / slideWidth)

      // Check if the scroll is at the end of the slider
      if (scrollLeft + containerWidth >= totalWidth) {
        // Set active index to the last item
        currentIndex = totalSlides - 1
      }

      setActiveIndex(currentIndex)
    }
  }

  const handleDotClick = (index: number) => {
    if (containerRef.current) {
      const slideWidthWithGap = slideWidth + 16 // assuming 16px is the gap between slides
      const scrollPosition = index * slideWidthWithGap

      ;(containerRef.current as HTMLDivElement).scrollTo({
        left: scrollPosition,
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = (containerRef.current as HTMLDivElement).offsetWidth
      const totalSlides = React.Children.count(children)
      const totalSlidesWidth = totalSlides * slideWidth + (totalSlides - 1) * 16
      setIsOverflowing(totalSlidesWidth > containerWidth)
    }
  }, [children, slideWidth])

  return (
    <>
      <StyledContainer ref={containerRef} onScroll={handleScroll} $isOverflowing={isOverflowing}>
        {React.Children.map(children, (child, index) => (
          <StyledSlide key={index} $width={slideWidth}>
            {child}
          </StyledSlide>
        ))}
      </StyledContainer>
      {isOverflowing && (
        <>
          <DSSpacer size={24} direction="vertical" />
          <StyledSliderNavigator>
            {Array.from({ length: React.Children.count(children) }, (_, index) => (
              <>
                {activeIndex === index ? (
                  <StyledActiveIndex $variant={variant} />
                ) : (
                  <StyledDot onClick={() => handleDotClick(index)} />
                )}
              </>
            ))}
          </StyledSliderNavigator>
        </>
      )}
    </>
  )
}
