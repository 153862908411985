import { DSFlex, FlexAlignItems } from '@/components/ds/Flex/Flex'
import { DSSpacer } from '@/components/ds/Spacer/Spacer'
import { DSText, FontFamilyType, TextColourType, TextVariantType } from '@/components/ds/Text/Text'
import { DSIcon, IconColourType, IconName, IconSize } from '../Icon/Icon'

export type TextAndIconProps = {
  text: string
  textVariant: TextVariantType
  textWeight: FontFamilyType
  textColour: TextColourType
  iconName: IconName
  iconSize: IconSize
  iconPosition: 'left' | 'right'
  iconColour?: IconColourType
  iconAndTextAlignment?: FlexAlignItems
}

export const DSTextAndIcon = ({
  text,
  textColour,
  textVariant,
  textWeight,
  iconName,
  iconSize,
  iconPosition,
  iconColour,
  iconAndTextAlignment = 'center',
}: TextAndIconProps) => {
  const additionalStyles = iconAndTextAlignment === 'flex-start' ? { marginTop: '2px' } : {}
  return (
    <DSFlex direction="row" justifyContent="flex-start" alignItems={iconAndTextAlignment}>
      <DSFlex direction={iconPosition === 'right' ? 'row' : 'row-reverse'} alignItems={iconAndTextAlignment}>
        <DSText as="span" variant={textVariant} weight={textWeight} colour={textColour}>
          {text}
        </DSText>
        <DSSpacer size={8} direction="horizontal" />
        <DSIcon name={iconName} size={iconSize} colour={iconColour} additionalStyles={additionalStyles} />
      </DSFlex>
    </DSFlex>
  )
}
