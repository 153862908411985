import styled from 'styled-components'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  span {
    margin-top: 10px;
  }

  span:first-child {
    margin-right: 8px;
  }

  .side-text {
    span {
      margin: 0;
      padding: 0;
      font-size: ${(props) => props.theme.v2.typography.body.xs.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.xs.lineHeight};
      font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};

      @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        font-size: ${(props) => props.theme.v2.typography.label.l.fontSize};
        line-height: ${(props) => props.theme.v2.typography.label.l.lineHeight};
        font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
      }
    }

    strong {
      font-family: ${(props) => props.theme.v2.font.lotaGrotesqueRegular};
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    justify-content: flex-start;
  }
`

const StyledRating = styled.span<{ $position: string }>`
  display: inline-block;
  background: url('/assets/img/trustpilot/star-ratings.png') no-repeat;
  overflow: hidden;
  text-indent: -9999px;
  text-align: left;
  height: 24px;
  width: 128px;
  background-position: ${(props) => props.$position};
  vertical-align: text-bottom;
`

type StarRatingProps = {
  rating: number
  children?: any
  noStyle?: boolean
}

const RATING_POSITION = [
  '',
  '',
  '-148px -0px',
  '-10px -0px',
  '-10px -34px',
  '-286px -0px',
  '-286px -34px',
  '-148px -34px',
  '-148px -68px',
  '-10px -68px',
  '-286px -68px',
]

export const TrustpilotStarRating = ({ rating, children, noStyle = false }: StarRatingProps) => {
  const ratingPosition = RATING_POSITION[Math.round(rating * 2)]

  if (noStyle) {
    return <>{ratingPosition && <StyledRating $position={ratingPosition} />}</>
  }

  return (
    <>
      {ratingPosition && (
        <StyledContainer>
          <StyledRating $position={ratingPosition} />

          <span className="side-text">{children}</span>
        </StyledContainer>
      )}
    </>
  )
}
