import { DSGridBlock } from '@/components/ds/Grid/Grid'
import React from 'react'
import { ButtonBlock } from '../ButtonBlock'
import { AccordionBlock } from './Accordion'
import { GridBlockRecord } from 'types'
import { TypographyBlock } from './Typography'
import { SimpleImageBlock } from './SimpleImage'
import { SpacerBlock } from './Spacer'
import { DsButtonBlock } from './DsButtonBlock'
import { ResponsiveGridBlock } from './ResponsiveGridBlock'
import { ImageGalleryBlock } from './ImageGallery'
import { D30IngredientListBlock } from '@/components/pages/daily30/dato-pdp/D30IngredientListBlock'
import { D30GalleryBlock } from '@/components/pages/daily30/dato-pdp/D30GalleryBlock'
import { FragmentComponent } from '@/types/graphql'
import { D30TrustpilotBlock } from '@/components/pages/daily30/dato-pdp/TrustpilotBlock'
import { ImageCarouselBlock } from '@/blocks/CarouselBlock'
import { VideoGroup } from '@/blocks/VideoGroup'
import { HomepageScienceIconGrid } from '@/components/pages/homepage2024/content/science/HomepageScienceIconGrid'

const fragment = `
  fragment GridBlockFragment on GridBlockRecord {
    __typename
    id
    content {
      ...AccordionBlockFragment
      ...SimpleImageBlockFragment
      ...TypographyBlockFragment
      ...DsButtonBlockFragment
      ...ResponsiveGridBlockFragment
      ...ImageGalleryBlockFragment
      ...SpacerBlockFragment
      ...D30IngredientListBlockFragment
      ...D30GalleryBlockFragment
      ...D30TrustpilotBlockFragment
      ...ImageCarouselBlockFragment
      ...VideoGroupFragment
      ...HomepageScienceIconGridFragment
    }
    backgroundColor
    mobileBackgroundColor
    rounded
    textAlign
    config
    padded
  }

  ${AccordionBlock.fragment}
  ${SimpleImageBlock.fragment}
  ${TypographyBlock.fragment}
  ${DsButtonBlock.fragment}
  ${ResponsiveGridBlock.fragment}
  ${ImageGalleryBlock.fragment}
  ${ImageCarouselBlock.fragment}
  ${VideoGroup.fragment}
  ${HomepageScienceIconGrid.fragment}
`

const renderBlock = (record) => {
  switch (record.__typename) {
    case 'ButtonRecord':
      return <ButtonBlock key={record.id} record={record} />
    case 'AccordionRecord':
      return <AccordionBlock key={record.id} record={record} />
    case 'TypographyRecord':
      return <TypographyBlock key={record.id} record={record} />
    case 'SimpleImageRecord':
      return <SimpleImageBlock key={record.id} record={record} />
    case 'SpacerRecord':
      return <SpacerBlock key={record.id} record={record} />
    case 'DsButtonRecord':
      return <DsButtonBlock key={record.id} record={record} />
    case 'ResponsiveGridBlockRecord':
      return <ResponsiveGridBlock key={record.id} record={record} />
    case 'ImageGalleryRecord':
      return <ImageGalleryBlock key={record.id} record={record} />
    case 'D30IngredientListRecord':
      return <D30IngredientListBlock key={record.id} />
    case 'D30GalleryRecord':
      return <D30GalleryBlock key={record.id} record={record} />
    case 'D30TrustpilotRecord':
      return <D30TrustpilotBlock key={record.id} record={record} />
    case 'ImageCarouselRecord':
      return <ImageCarouselBlock key={record.id} record={record} />
    case 'VideoGroupRecord':
      return <VideoGroup key={record.id} record={record} />
    case 'HomepageScienceIconGridRecord':
      return <HomepageScienceIconGrid key={record.id} record={record} />
  }
}

export const GridBlock: FragmentComponent<{}, GridBlockRecord> = ({ record }) => {
  return (
    <DSGridBlock
      breakConfig={record?.config}
      backgroundColor={record?.backgroundColor}
      mobileBackgroundColor={record?.mobileBackgroundColor}
      rounded={record?.rounded}
      textAlign={record?.textAlign}
      padded={record?.padded}
    >
      {record?.content?.map((record) => renderBlock(record))}
    </DSGridBlock>
  )
}

GridBlock.fragment = fragment
GridBlock.recordName = 'GridBlockRecord'
