import { TrustpilotRatingNoLogo } from '@/components/shared/Trustpilot'

const fragment = `
  fragment D30TrustpilotBlockFragment on D30TrustpilotRecord {
    __typename
    id
  }
`

export const D30TrustpilotBlock = ({ record }) => {
  return <TrustpilotRatingNoLogo />
}

D30TrustpilotBlock.fragment = fragment
D30TrustpilotBlock.recordName = 'D30TrustpilotRecord'
