import React from 'react'
import { ButtonBlock } from '../../ButtonBlock'
import { AccordionBlock } from '../Accordion'
import { ResponsiveGridAreaRecord } from 'types'
import { TypographyBlock } from '../Typography'
import { SimpleImageBlock } from '../SimpleImage'
import { SpacerBlock } from '../Spacer'
import { DsButtonBlock } from '../DsButtonBlock'
import { ResponsiveGridBlock } from '../ResponsiveGridBlock'
import styled from 'styled-components'
import { BREAKPOINTS } from '@/components/ds/breakpoints'
import { ImageGalleryBlock } from '../ImageGallery'
import { D30AvailabilityCheckerBlock } from '@/components/pages/daily30/dato-pdp/D30AvailabilityCheckerBlock'
import { D30FeatureListBlock } from '@/components/pages/daily30/dato-pdp/D30FeatureListBlock'
import { D30TrustpilotBlock } from '@/components/pages/daily30/dato-pdp/TrustpilotBlock'
import { FragmentComponent } from '@/types/graphql'
import { HomepagePricingCard } from '@/components/pages/homepage2024/content/HomepagePricingCard/HomepagePricingCard'
import { HomepageTrustpilot } from '@/components/shared/Trustpilot'

const fragment = `
  fragment ResponsiveGridAreaFragment on ResponsiveGridAreaRecord {
    __typename
    id
    gridAreaId
    justifyItems
    mobileJustifyItems
    content {
      __typename
      ...AccordionBlockFragment
      ...TypographyBlockFragment
      ...SimpleImageBlockFragment
      ...SpacerBlockFragment
      ...DsButtonBlockFragment
      ...ImageGalleryBlockFragment
      ...D30AvailabilityCheckerBlockFragment
      ...D30FeatureListBlockFragment
      ...D30TrustpilotBlockFragment
      ...HomepagePricingCardFragment
    }
  }

  ${HomepagePricingCard.fragment}
`

const renderBlock = (record, index) => {
  const key = `${record.id}-${index}}`
  switch (record.__typename) {
    case 'ButtonRecord':
      return <ButtonBlock key={key} record={record} />
    case 'AccordionRecord':
      return <AccordionBlock key={key} record={record} />
    case 'TypographyRecord':
      return <TypographyBlock key={key} record={record} />
    case 'SimpleImageRecord':
      return <SimpleImageBlock key={key} record={record} />
    case 'SpacerRecord':
      return <SpacerBlock key={key} record={record} />
    case 'DsButtonRecord':
      return <DsButtonBlock key={key} record={record} />
    case 'ResponsiveGridBlockRecord':
      return <ResponsiveGridBlock key={key} record={record} />
    case 'ImageGalleryRecord':
      return <ImageGalleryBlock key={key} record={record} />
    case 'D30AvailabilityCheckerBlockRecord':
      return <D30AvailabilityCheckerBlock key={key} record={record} />
    case 'D30FeatureListRecord':
      return <D30FeatureListBlock key={key} record={record} />
    case 'D30TrustpilotRecord':
      return <D30TrustpilotBlock key={key} record={record} />
    case HomepagePricingCard.recordName:
      return <HomepagePricingCard key={key} record={record} />
    case 'HomepageTrustpilotRecord':
      return <HomepageTrustpilot key={key} />
  }
}

const StyledResponsiveGridArea = styled.div<{
  $gridAreaId: string
  $justifyItems: string
  $mobileJustifyItems: string
}>`
  display: grid;
  grid-area: ${({ $gridAreaId }) => `${$gridAreaId}`};
  justify-items: ${({ $justifyItems }) => `${$justifyItems}`};

  @media (max-width: ${BREAKPOINTS.m}) {
    justify-items: ${({ $mobileJustifyItems }) => `${$mobileJustifyItems}`};
  }
`
export const ResponsiveGridAreaBlock: FragmentComponent<{}, ResponsiveGridAreaRecord> = ({ record }) => {
  return (
    <StyledResponsiveGridArea
      $gridAreaId={record?.gridAreaId}
      $justifyItems={record?.justifyItems}
      $mobileJustifyItems={record?.mobileJustifyItems}
    >
      {record?.content?.map((record, index) => renderBlock(record, index))}
    </StyledResponsiveGridArea>
  )
}

ResponsiveGridAreaBlock.fragment = fragment
ResponsiveGridAreaBlock.recordName = 'ResponsiveGridAreaRecord'
