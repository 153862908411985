import { IngredientFAQBody } from '@/components/pages/daily30/product-details-page/faq/IngredientFAQBody'
import { USIngredientFAQBody } from '@/components/pages/daily30/product-details-page/faq/USIngredientFAQBody'
import { GeoContext } from '@/context/geoContext'
import { FragmentComponent } from '@/types/graphql'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { StructuredText } from 'react-datocms'
import { AccordionRecord } from 'types'
import { HomepageAccordionGalleryBlock } from '@/components/pages/homepage2024/content/HomepageAccordionGalleryBlock'
import { StyledOverrideDefaultStyles } from '@/blocks/DesignSystem/DSStyledStructuredText'
import { TrackingContext } from '@/context/trackingContext'
import { DSAccordion } from '@zoe/ds-web'
import { getBreakpointForWidth } from '@/components/ds/breakpoints'

const fragment = `
  fragment AccordionBlockFragment on AccordionRecord {
    __typename
    id
    asGallery
    expandFirstSection
    variant
    items {
      title
      content {
        value
      }
      titleIcon
      iconBackgroundColour
      imageContent {
        ...SimpleImageBlockFragment
      }
    }
  }
`

export const AccordionBlock: FragmentComponent<{}, AccordionRecord> = ({ record }) => {
  const { country } = useContext(GeoContext)
  const { trackAccordionClick } = useContext(TrackingContext)
  const [expandFirstSection, setExpandFirstSection] = useState<boolean>()

  useEffect(() => {
    const breakpoint = getBreakpointForWidth(window.innerWidth)
    const shouldExpand = ['s', 'm', 'l', 'xl'].some(
      (bp: any) => (record.expandFirstSection ?? []).includes(bp) && breakpoint === bp,
    )

    setExpandFirstSection(shouldExpand)
  }, [record.expandFirstSection])

  if (expandFirstSection === undefined) {
    return null
  }

  if (record.asGallery) {
    return <HomepageAccordionGalleryBlock record={record} expandFirstSection={expandFirstSection} />
  }

  return (
    <DSAccordion
      content={record?.items?.map(({ title, content, titleIcon, iconBackgroundColour }, index) => ({
        title,
        titleIcon,
        titleIconBackground: iconBackgroundColour,
        body: JSON.stringify(content?.value).includes('[D30IngredientFAQModal]') ? (
          country === 'US' ? (
            <USIngredientFAQBody />
          ) : (
            <IngredientFAQBody />
          )
        ) : (
          <StyledOverrideDefaultStyles>
            <StructuredText data={content.value} />
          </StyledOverrideDefaultStyles>
        ),
        id: index.toString(),
      }))}
      variant={record.variant || 'default'}
      trackAccordionClick={trackAccordionClick}
      expandFirstSection={expandFirstSection}
    />
  )
}

AccordionBlock.fragment = fragment
AccordionBlock.recordName = 'AccordionRecord'
