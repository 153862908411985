import { useEffect, useState } from 'react'
import { theme } from 'styles/theme'
import { Breakpoint, getBreakpointForWidth } from '@/components/ds/breakpoints'

interface IWindowSize {
  width: number | undefined
  type: 'mobile' | 'tablet' | 'desktop' | null
  isMobile: boolean
  breakpoint?: Breakpoint
}

const getWindowSize = (width: number): IWindowSize => {
  if (width <= parseInt(theme.breakpoints.mobile)) {
    return { width, type: 'mobile', isMobile: true }
  } else if (width <= parseInt(theme.breakpoints.tablet)) {
    return { width, type: 'tablet', isMobile: false }
  } else if (width > parseInt(theme.breakpoints.tablet)) {
    return { width, type: 'desktop', isMobile: false }
  } else {
    return { width, type: null, isMobile: false }
  }
}

export const useWindowSize = (): IWindowSize => {
  const [windowData, setWindowData] = useState<IWindowSize>({
    width: undefined,
    type: null,
    isMobile: true,
  })

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth } = window
      const windowData = getWindowSize(innerWidth)
      const breakpoint = getBreakpointForWidth(innerWidth)
      setWindowData({ ...windowData, ...{ breakpoint } })
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return windowData
}
