import { BREAKPOINTS, convertPxToNumber } from '@/components/ds/breakpoints'
import { DatoImage } from '@/components/elements/global/DatoImage'
import { responsiveImageBlock } from '@/graphql/utils/commonBlocks'
import { useWindowSize } from '@/lib/hooks/useWindowSize'
import { FragmentComponent } from '@/types/graphql'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { SimpleImageRecord } from 'types'

const imageBlock = responsiveImageBlock()

const fragment = `
  fragment SimpleImageBlockFragment on SimpleImageRecord {
    __typename
    id
    image {
      ${imageBlock}
    }
    imageMobile {
      ${imageBlock}
    }
    rounded
  }
`

const StyledSimpleImageContainer = styled.div<{ $rounded?: boolean }>`
  display: flex;
  justify-content: center;

  img {
    border-radius: ${({ $rounded }) => ($rounded ? 'var(--radius-16)' : '0')};
  }
`

export const SimpleImageBlock: FragmentComponent<{}, SimpleImageRecord> = ({ record }) => {
  const window = useWindowSize()

  // use width to prevent rendering both images to the DOM and then hiding one
  const { responsiveImage } = useMemo(() => {
    const isMobile = (window.width ?? 0) < convertPxToNumber(BREAKPOINTS.m)
    return isMobile && record.imageMobile ? record.imageMobile : record.image
  }, [window.width, record])

  return (
    <StyledSimpleImageContainer $rounded={record.rounded}>
      <DatoImage data={responsiveImage} />
    </StyledSimpleImageContainer>
  )
}

SimpleImageBlock.fragment = fragment
SimpleImageBlock.recordName = 'SimpleImageRecord'
