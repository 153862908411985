import { DSSpacer } from '@/components/ds/Spacer/Spacer'
import { FragmentComponent } from '@/types/graphql'
import React from 'react'
import { SpacerRecord } from 'types'
import { useExperimentContext } from '@/context/ExperimentContext'

const fragment = `
  fragment SpacerBlockFragment on SpacerRecord {
    __typename
    id
    size
    direction
    breakat
    dataTestId
  }
`

export const SpacerBlock: FragmentComponent<{}, SpacerRecord> = ({ record }) => {
  const { shouldRenderContent } = useExperimentContext()
  if (!shouldRenderContent(record.dataTestId)) {
    return null
  }
  return (
    <DSSpacer size={record?.size} direction={record?.direction ? 'vertical' : 'horizontal'} breakAt={record?.breakat} />
  )
}

SpacerBlock.fragment = fragment
SpacerBlock.recordName = 'SpacerRecord'
