import { FragmentComponent } from '@/types/graphql'
import { DSDivider, DSFlex, DSIcon, DSSpacer, DSText, DSTextEm, TextColourType } from '@zoe/ds-web'
import { styled } from 'styled-components'
import React from 'react'
import { responsiveImageBlock } from '@/graphql/utils/commonBlocks'
import { Image } from 'react-datocms'
import { HomepagePricingCardRecord } from 'types'
import { HomepagePricingCardPricingGrid } from './HomepagePricingCardPricingGrid'

const fragment = `
  fragment HomepagePricingCardFragment on HomepagePricingCardRecord {
    __typename
    title
    badgeText
    backgroundColour
    textColour
    gutHealthRetestImage {
      ${responsiveImageBlock({ width: 32, height: 32 })}
    }
    gutHealthRetestLabel
    gutHealthRetestIcon
    gutHealthRetestIconColour
    testingPrice
    membershipPrice
  }
`

const StyledPricingCard = styled.div<{ $backgroundColour: string }>`
  ${({ $backgroundColour }) => `background-color: var(--colour-surface-${$backgroundColour})`};
  padding: var(--grid-24) var(--grid-16);
  border-radius: var(--radius-16);
  height: 100%;
  width: 100%;

  @media screen and (min-width: 768px) {
    max-width: 370px;
  }
`

const StyledPill = styled.div`
  background-color: var(--colour-surface-neutral);
  border-radius: var(--radius-24);
  padding: var(--grid-4) var(--grid-12);
`

export const HomepagePricingCard: FragmentComponent<{}, HomepagePricingCardRecord> = ({ record }) => {
  const textColour = record.textColour as TextColourType

  return (
    <StyledPricingCard key={record.title} $backgroundColour={record.backgroundColour}>
      <DSFlex direction="row" justifyContent="space-between" alignItems="center">
        <DSTextEm variant="fluid-heading-700" colour={textColour}>
          {record.title}
        </DSTextEm>
        <StyledPill>
          <DSText variant="fluid-label-100">{record.badgeText}</DSText>
        </StyledPill>
      </DSFlex>

      <DSSpacer direction="vertical" size={16} />

      <DSFlex direction="row" justifyContent="space-between" alignItems="center">
        <DSFlex direction="row" justifyContent="flex-start" alignItems="center" gap={4}>
          {/* disabling as alt comes from DatoCMS */}
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <Image data={record.gutHealthRetestImage.responsiveImage} />
          <DSText variant="fluid-label-100" weight="lotaLight" colour={textColour}>
            {record.gutHealthRetestLabel}
          </DSText>
        </DSFlex>
        <DSIcon
          name={record.gutHealthRetestIcon}
          colour={record.gutHealthRetestIconColour ?? 'primary'}
          size={16}
          additionalStyles={{ strokeWidth: 3 }}
        />
      </DSFlex>

      <DSSpacer direction="vertical" size={16} />

      {record.backgroundColour === 'navy-bold' ? <DSDivider variant="secondary" /> : <DSDivider variant="tertiary" />}

      <DSSpacer direction="vertical" size={16} />

      <HomepagePricingCardPricingGrid record={record} />
    </StyledPricingCard>
  )
}

HomepagePricingCard.fragment = fragment
HomepagePricingCard.recordName = `HomepagePricingCardRecord`
