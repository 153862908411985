import { D30_GB_NO_STOCK_WAITLIST_EMAIL_SUBMITTED } from '@/lib/braze/brazeConstants'
import { D30Waitlist } from './D30Waitlist'

interface D30NoStockWailtistProps {
  country: string
}

export const D30NoStockWailtist = ({ country }: D30NoStockWailtistProps) => {
  return (
    <D30Waitlist
      title="Currently out of stock"
      subtitle="Sign up to be notified when it's available."
      brazeEventName={D30_GB_NO_STOCK_WAITLIST_EMAIL_SUBMITTED}
      country={country}
    />
  )
}
