import styled from 'styled-components'
import { StructuredText, StructuredTextGraphQlResponse } from 'react-datocms'

export const StyledOverrideDefaultStyles = styled.div`
  p {
    font-size: var(--font-size-fluid-paragraph-300);
    line-height: var(--line-height-fluid-paragraph-300);
    font-weight: var(--font-weight-fluid-paragraph-300);
    font-family: var(--font-sans-serif-light), var(--font-fallback-sans-serif);
    margin: 0;
  }
  a {
    font-family: var(--font-sans-serif-semibold), var(--font-fallback-sans-serif);
    color: var(--colour-text-link);
    text-decoration: none;
  }
  strong,
  b {
    font-family: var(--font-sans-serif-semibold), var(--font-fallback-sans-serif);
  }
`

interface DSStyledStructuredTextProps {
  data: StructuredTextGraphQlResponse
}

export const DSStyledStructuredText = ({ data }: DSStyledStructuredTextProps) => {
  return (
    <StyledOverrideDefaultStyles>
      <StructuredText data={data} />
    </StyledOverrideDefaultStyles>
  )
}
